<template>
  <b-overlay :show="formShow" rounded="sm" no-fade>
    <!-- Stats Card Vertical -->
    <b-row class="match-height">
      <b-col xl="2" md="4">
        <statistic-card-vertical icon="UsersIcon" :statistic="responses.length" statistic-title="Total Staff" color="primary" />
      </b-col>
      <b-col xl="2" md="4" sm="6">
        <statistic-card-vertical color="info" icon="ActivityIcon" :statistic="selectedSection.averageScore" statistic-title="Average Score" />
      </b-col>
      <b-col xl="2" md="4" sm="6">
        <statistic-card-vertical color="success" icon="UserCheckIcon" :statistic="selectedSection.totalPassed" statistic-title="Total Passed" />
      </b-col>
      <b-col xl="2" md="4" sm="6">
        <statistic-card-vertical color="danger" icon="UserXIcon" :statistic="selectedSection.totalFailed" statistic-title="Total Failed" />
      </b-col>
      <b-col xl="4" md="4" sm="6">
        <b-card>
          <v-select v-model="selectedSection" :options="sections" :value="selectedSection.name" label="name" append-to-body :clearable="false" :reduce="(val) => val" @input="test"> </v-select>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col xl="12">
        <b-card>
          <template>
            <b-table hover head-variant="dark" select-mode="single" class="position-relative" :items="responses" :fields="fields" striped responsive>
              <template #cell(show_details)="row">
                <b-form-checkbox v-model="row.detailsShowing" plain class="vs-checkbox-con" @change="row.toggleDetails">
                  <span class="vs-checkbox">
                    <span class="vs-checkbox--check">
                      <i class="vs-icon feather icon-check" />
                    </span>
                  </span>
                  <span class="vs-label">{{ row.detailsShowing ? 'Hide' : 'Show' }}</span>
                </b-form-checkbox>
              </template>

              <!-- <template #cell(field)="row" v-for="field in fields" v-if="field != 'show_details' || field != 'id' || field != 'responder' || field != 'total'">
                {{ row }}
              </template> -->

              <template #row-details="row">
                <b-row class="mb-2">
                  <b-col md="12" class="mb-1">
                    <b-table-simple striped hover small caption-top responsive>
                      <b-thead head-variant="light">
                        <b-tr>
                          <b-th class="text-center" colspan="2">Question</b-th>

                          <b-th class="text-center" v-for="section in subfields" :key="section.index" colspan="2">
                            {{ section }}
                          </b-th>
                          <b-th class="text-center" colspan="2">Grand Total</b-th>
                        </b-tr>
                      </b-thead>

                      <b-tbody v-for="question in filteredResponses[row.item.id]" :key="question.id">
                        <b-tr v-if="question.questionInfo == true">
                          <b-th colspan="2">
                            {{ question.question }}
                          </b-th>

                          <b-td v-for="section in subfields" :key="section.index" colspan="2" class="text-center">
                            <span v-if="section == question.section">{{ question.response ? question.response : '' }}</span>
                            <span v-if="section != question.section"></span>
                          </b-td>
                          <b-td colspan="2" class="text-center"> {{ question.response }}</b-td>
                        </b-tr>

                        <b-tr v-if="question.questionInfo == false">
                          <b-td :colspan="5 + 2 * row.item.section.length" variant="secondary"> {{ question.response }}</b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </b-col>
                </b-row>

                <b-button size="sm" variant="outline-secondary" @click="row.toggleDetails">
                  Hide Details
                </b-button>
              </template>
            </b-table>
          </template>
        </b-card>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import { BRow, BCol, BMedia, BMediaAside, BFormCheckbox, BButton } from 'bootstrap-vue';
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue';
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue';
import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue';
import StatisticCardWithLineChart from '@core/components/statistics-cards/StatisticCardWithLineChart.vue';
import { kFormatter } from '@core/utils/filter';
import FormStatisticOrderChart from './FormStatisticOrderChart.vue';
import FormStatisticProfitChart from './FormStatisticProfitChart.vue';
import FormStatisticsGroup from './FormStatisticsGroup.vue';
import { VueGoodTable } from 'vue-good-table';
import formStoreModule from './formStoreModule';
import store from '@/store';
import { ref, onUnmounted, reactive } from '@vue/composition-api';
import vSelect from 'vue-select';
import router from '@/router';

export default {
  components: {
    BRow,
    BCol,
    BMedia,
    BMediaAside,
    BFormCheckbox,
    BButton,
    StatisticCardVertical,
    StatisticCardHorizontal,
    StatisticCardWithAreaChart,
    StatisticCardWithLineChart,
    FormStatisticOrderChart,
    FormStatisticProfitChart,
    FormStatisticsGroup,
    VueGoodTable,
    vSelect,
  },
  data() {
    return {
      formShow: false,
      fields: ['show_details', 'id', 'responder'],
      subfields: [],
      responses: [],
      filteredResponses: [],
      statistics: {},
      selectedSection: { name: 0, formId: 0, averageScore: 0, totalFailed: 0, totalPassed: 0, score: 0 },
      id: router.currentRoute.params.formId,
      sections: [{ name: 'All Sections', formId: '', averageScore: '', totalFailed: '', totalPassed: '' }],
    };
  },

  setup() {
    const FORM_APP_STORE_MODULE_NAME = 'form';
    // Register module
    if (!store.hasModule(FORM_APP_STORE_MODULE_NAME)) store.registerModule(FORM_APP_STORE_MODULE_NAME, formStoreModule);
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FORM_APP_STORE_MODULE_NAME)) store.unregisterModule(FORM_APP_STORE_MODULE_NAME);
    });
  },

  created() {
    this.getFormList();
    this.getFormStatistics();
  },
  methods: {
    kFormatter,

    getFormList() {
      const sectionFields = [];
      this.formShow = true;
      store.dispatch('form/getFormList', this.id).then((response) => {
        this.responses = response.data;
        this.responses.forEach((element) => {
          this.filteredResponses[element.id] = [];
          element.questions.forEach((question) => {
            this.filteredResponses[element.id].push(question);
          });
        });
        this.formShow = false;
      });
    },
    getFormStatistics() {
      this.formShow = true;
      store.dispatch('form/getFormStatistics', this.id).then((response) => {
        this.statistics = response.data;
        this.sections[0].formId = this.id;
        this.sections[0].averageScore = response.data.averageScore;
        this.sections[0].totalFailed = response.data.totalFailed;
        this.sections[0].totalPassed = response.data.totalPassed;
        this.selectedSection = this.sections[0];

        response.data.sections.forEach((element) => {
          this.sections.push(element);
          this.fields.push(element.name);
          this.subfields.push(element.name);
        });
        this.fields.push('total');

        this.formShow = false;
      });
    },

    test() {
      this.fields = ['show_details', 'id', 'responder'];
      this.subfields = [];
      this.filteredResponses = [];

      if (this.selectedSection.name == 'All Sections') {
        this.responses.forEach((element) => {
          this.filteredResponses[element.id] = [];
          element.questions.forEach((question) => {
            this.filteredResponses[element.id].push(question);
          });
        });

        this.sections.forEach((element) => {
          if (element != 'All Sections') {
            this.fields.push(element.name);
            this.subfields.push(element.name);
          }
        });
      } else {
        this.fields.push(this.selectedSection.name);
        this.subfields.push(this.selectedSection.name);

        this.responses.forEach((element) => {
          this.filteredResponses[element.id] = [];
          element.questions.forEach((question) => {
            if (question.section == this.selectedSection.name) {
              this.filteredResponses[element.id].push(question);
            }
          });
        });
      }
      this.fields.push('total');
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
